import * as React from "react";
import { graphql } from "gatsby";
import TemplatePage from "../../../../components/pages/templates/TemplatePage";

const Template = ({ data }) => (
  <TemplatePage
    title="Weather Website Template | Weather Web Design | Uizard"
    canonical="/templates/web-app-templates/weather-web-app-dark/"
    category="Web App Templates"
    url="/templates/web-app-templates/weather-web-app-light/"
    metaDescription="Looking to design your own weather website or web app? Our new weather website design template is here to streamline your project. Try now!"
    description="
    h2:Breeze; our weather forecast web app template in light mode
    <br/>
    Looking to design your own weather website or web app? Our new weather website design template, Breeze, is here to streamline your project. Created by professional designers, Breeze is a <a:https://uizard.io/mockups/>UX/UI mockup</a> that puts serious style into weather forecasting.
    <br/>
    h3:Cloudy with a chance of rapid prototypes
    <br/>
    As with all our <a:https://uizard.io/templates/>UI templates</a>, Breeze comes with all must-have UX considerations and user journeys built in, meaning you can go from idea to prototype faster than ever before. To get started, simply sign up to Uizard Pro, select the template, and adapt it to suit your own project goals or branding.
    <br/>
    h3:Forecast: easily customizable designs with limitless collaboration
    <br/>
    Uizard is designed for customization and collaboration. You can adapt our templates using Uizard's drag-and-drop editor and invite team members to collaborate in real-time. Web design has never been so easy.
    "
    pages={[
      "A stunning home screen with classic visuals",
      "Weather forecast screen by location, with day of the week widget",
      "A 'my cities' screen where users can track specific locales",
      "Geographical map screen with a multi-weather view",
    ]}
    projectCode="4oQXAjr683f3AqM43y31"
    img1={data.image1.childImageSharp}
    img1alt="weather web app design template light mode cover"
    img2={data.image2.childImageSharp}
    img2alt="weather web app design template light mode home screen"
    img3={data.image3.childImageSharp}
    img3alt="weather web app design template light mode search screen"
    img4={data.image4.childImageSharp}
    img4alt="weather web app design template light mode map screen"
    img5={data.image5.childImageSharp}
    img5alt="weather web app design template light mode summary"
  />
);

export default Template;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    image1: file(
      relativePath: {
        eq: "templates/weather-web-app-light/weather-web-light-cover.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image2: file(
      relativePath: {
        eq: "templates/weather-web-app-light/weather-web-light-home.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image3: file(
      relativePath: {
        eq: "templates/weather-web-app-light/weather-web-light-search.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image4: file(
      relativePath: {
        eq: "templates/weather-web-app-light/weather-web-light-map.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image5: file(
      relativePath: {
        eq: "templates/weather-web-app-light/weather-web-light-summary.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`;
